<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-08-05 17:06:58
-->
<template>
	<div class="recruitList">
		<el-tabs v-model="currentTab" @tab-click="changeTab">
			<el-tab-pane label="全部" name="3"></el-tab-pane>
			<el-tab-pane label="未开始" name="0"></el-tab-pane>
			<el-tab-pane label="进行中" name="1"></el-tab-pane>
			<el-tab-pane label="已完成" name="2"></el-tab-pane>
		</el-tabs>
		<div class="recruitList-data">
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">活动名称</span>
					<el-input class="common-row-ipt" placeholder="请输入活动名称" v-model="searchForm.name" />
				</div>
				<div class="common-row-item">
					<span class="common-row-label">活动时间</span>
					<el-date-picker class="common-row-ipt" :clearable="false" value-format="yyyy-MM-dd"
						@change="dateChange" v-model="searchForm.time" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</div>
				<div class="common-row-item">
					<el-button @click="searchFn" type="primary">查询</el-button>
					<el-button @click="resetFn">重置</el-button>
				</div>
			</div>
			<el-table :data="tableData" border>
				<!-- <el-table-column width="55px" type="index" label="序号">
				</el-table-column> -->
				<el-table-column  prop="action" label="操作">
					<template slot-scope="scope">
						<div class="table-action">
							<span @click="actionFn('detail', scope)">详情</span>
							<!-- <el-divider direction="vertical"></el-divider>
							<span @click="actionFn('inform', scope)">通知</span> -->
						</div>
					</template>
				</el-table-column>
				<el-table-column  prop="id" label="招募ID">
				</el-table-column>
				<el-table-column prop="recruitName" label="招募名称">
				</el-table-column>
				<el-table-column width="300px" prop="recruitTimeList" label="活动时间">
					<template slot-scope="scope" v-if="scope.row.recruitTimeList&&scope.row.recruitTimeList.length > 0">
						<div class="table-action-date">
							<span v-for="(item, index) in scope.row.recruitTimeList"
								:key="index">
								<span v-if="item.timeStr">{{item.timeStr}}</span>
								<span v-else>{{`${item.starTime} 至 ${item.endTime}`}}</span>
							</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column  prop="activityType" label="活动类型">
					<template slot-scope="scope">
						<span>{{scope.row.activityType == 1? "一般活动":"共建基地活动"}}</span>
					</template>
				</el-table-column>
				<el-table-column  prop="status" label="招募状态">
					<template slot-scope="scope">
						<span :class="'table-action-status'+ scope.row.status">{{scope.row.status == 0? "未开始":scope.row.status == 1? "进行中":"已完成"}}</span>
					</template>
				</el-table-column>
				<el-table-column  type="timingRecruitTime" label="定时招募">
					<template slot-scope="scope">
						<span>{{scope.row.timingRecruitTime? dateFormat(scope.row.timingRecruitTime):"无"}}</span>
					</template>
				</el-table-column>
			</el-table>
			<div class="common-pageNation">
				<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
					:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getRecruitList
	} from "@/api/recruit";
	export default {
		data() {
			return {
				currentTab: "3",
				searchForm: {
					name: "",
					time: ""
				},
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				starTime: "",
				endTime: ""
			};
		},
		mounted() {
			this.recruitList();
		},
		methods: {
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(":00", "")
				}
			},
			changeTab(e) {
				this.currentTab = e.name
				this.pageIndex = 1;
				this.recruitList();
			},
			searchFn() {
				this.pageIndex = 1;
				this.recruitList();
			},
			resetFn() {
				this.pageIndex = 1;
				this.searchForm = {
					name: "",
					time: ""
				};
				this.starTime = "";
				this.endTime = "";
				this.recruitList();
			},
			currentChange(val) {
				this.pageIndex = val;
				this.recruitList();
			},
			dateChange(value) {
				console.log("dateChange", value);
				if (value) {
					this.starTime = value[0];
					this.endTime = value[1];
				} else {
					this.starTime = "";
					this.endTime = "";
				}
			},
			recruitList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
				}
				if (this.searchForm.name) {
					this.$set(params, "activityName", this.searchForm.name)
				}
				if (this.searchForm.time) {
					this.$set(params, "activityTimeStart", this.starTime)
					this.$set(params, "activityTimeEnd", this.endTime)
				}
				if (this.currentTab != 3) {
					this.$set(params, "status", this.currentTab)
				}
				getRecruitList(params).then(res => {
					if (res.code == 0) {
						this.tableData = res.data;
						this.totalCount = res.page.totalCount * 1
					}
				});
			},
			actionFn(type, scope) {
				switch (type) {
					case "detail":
						// let routeData = this.$router.resolve({
						// 	path:"/recruitList/detail",
						// 	query:{recruitId:scope.row.id}
						// })
						// window.open(routeData.href,"_blank")
						this.$router.push({
							path: `/recruitList/detail?recruitId=${scope.row.id}`
						});
						break;
					case "inform":
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.el-button {
		height: 36px;
	}

	.table-action-date {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		span {
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 20px;
			color: #303133;
		}
	}
	
	.table-action-status1 {
		color: #F5803E;
	}
	
	.table-action-status2 {
		color: #32AA5C;
	}

	.recruitList {
		display: flex;
		flex-direction: column;

		.recruitList-data {
			display: flex;
			flex-direction: column;
			padding: 0 20px;

			.common-row {
				margin: 16px 0;

				.common-row-item {
					margin-right: 20px;

					.common-row-ipt {
						flex: 1;
						height: 36px;
						line-height: 36px;
						border: 1px solid #edf1f7;
						border-left: 0;
					}
				}
			}
		}
	}
</style>
